// 项目机会管理  reserveProject
// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const httpServer = {
    // 项目列表各状态计数
    reserveProjectCount(params) {
        return get("/reserveproject/reserve-project/counts", params);
    },
    // 项目列表查询
    reserveProjectList(params) {
        return postJson("/reserveproject/reserve-project/table", params);
    },
    // 项目机会详情查询
    reserveProjectDetails(id) {
        return get(`/reserveproject/reserve-project/findById/${id}`);
    },
    // 项目机会详情-跟进记录-查询
    reserveProjectfollowRecord(id) {
        return get(`/reserveproject/follow/findById/${id}`);
    },
    // 项目机会详情-保存
    reserveProjectSave(params) {
        return postJson(`/reserveproject/reserve-project/save`, params);
    },
    // 项目机会详情-干系人-查询
    reservePersonList(id, type) {
        return get(
            `/reserveproject/project-customer-link-relevance/find/${id}`,
            { linkType: type || "" }
        );
    },
    // 项目机会-生成项目
    createItem(params) {
        return postJson(`/project/project/saveByReserve`, params);
    },
    // 项目机会-删除
    delReserveItem(id) {
        return get(`/reserveproject/reserve-project/delete/${id}`);
    },
    // 跟进记录列表查询
    followReserve(params) {
        return postJson("/reserveproject/follow/findList", params);
    },
    // 跟进记录保存
    saveFollow(params) {
        return postJson("/reserveproject/follow/save", params);
    },
};

export default httpServer;
