// 项目机会记录
<template>

    <div class='reserveProjectRecord baseBg'>
        <div class="listBox mainWhiteBg">
            <el-tabs v-model="activeName">
                <el-tab-pane name="储备项目">
                    <div class="tabsLabel"
                         slot="label">
                        <el-badge :type="activeName == '储备项目' ? 'primary' : 'warning'"
                                  :value="pageTotal"
                                  class="item">
                            储备项目
                        </el-badge>
                    </div>

                    <div class="tableOpportunity">

                        <!-- 搜索条件 -->
                        <div class="searchForm">
                            <el-form :model="searchForm"
                                     ref="searchForm"
                                     :rules="searchFormrules"
                                     :inline="true">
                                <el-form-item label="项目名称"
                                              prop="name">
                                    <el-input v-model="searchForm.name"
                                              placeholder="请输入项目名称"></el-input>
                                </el-form-item>
                                <el-form-item label="项目类型"
                                              prop="type">
                                    <el-select v-model="searchForm.type"
                                               placeholder="请选择项目类型"
                                               clearable>
                                        <el-option v-for="item in typeOpts"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="项目客户"
                                              prop="customerCompanyId">
                                    <customer-or-provider-select-tree ref="selectTree"
                                                                      type="CUSTOMER"
                                                                      :shouldClean="true"
                                                                      @handClear="handClear"
                                                                      @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                                </el-form-item>
                                <el-form-item label="软件信息"
                                              prop="systemsInvolvedId">
                                    <select-project ref="selectProject"
                                                    @select="selectSystem"></select-project>
                                </el-form-item>
                                <el-form-item label="状态"
                                              prop="status">
                                    <el-select v-model="searchForm.status"
                                               placeholder="请选择状态"
                                               clearable>
                                        <el-option v-for="item in statusOpts"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <br>
                                <el-form-item label="完成日期"
                                              prop="finishTimeLeft">
                                    <el-date-picker v-model="searchForm.finishTimeLeft"
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    type="datetime"
                                                    placeholder="请选择完成日期">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="~"
                                              prop="finishTimeRight">
                                    <el-date-picker v-model="searchForm.finishTimeRight"
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    type="datetime"
                                                    placeholder="请选择项目储备日期">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary"
                                               @click="getList">搜索</el-button>
                                    <el-button @click="resetSearchForm">清除</el-button>
                                </el-form-item>
                            </el-form>
                        </div>

                        <!-- 列表 -->
                        <div class="tableBox">
                            <el-table :data="tableData.data"
                                      :height="tableHeight"
                                      border
                                      stripe>
                                <el-table-column type="index"
                                                 label="序号"
                                                 align="center"
                                                 width="50">
                                </el-table-column>
                                <el-table-column v-for="col in tableData.columns"
                                                 align="center"
                                                 :prop="col.id"
                                                 :key="col.id"
                                                 :label="col.label"
                                                 :min-width="col.width">
                                </el-table-column>
                                <el-table-column prop="id"
                                                 label="操作"
                                                 align="center"
                                                 min-width="100">
                                    <template slot-scope="scope">
                                        <el-link type="primary"
                                                 @click="details(scope.row)">查看详情</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <Pagination :page="searchForm.page"
                                        :limit="searchForm.size"
                                        :total="pageTotal"
                                        @pagination="paginationChange" />
                        </div>
                    </div>
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>

</template>

<script>
import selectProject from "@/components/commonSelect/selectProject.vue";
import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree.vue";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/reserveProject.js";
export default {
    name: "reserveProjectRecord",

    props: [],

    components: {
        Pagination,
        "customer-or-provider-select-tree": customerOrProviderSelectTree,
        "select-project": selectProject,
    },

    data() {
        return {
            activeName: "储备项目",
            searchForm: {
                name: "", // 项目名称
                type: "", // 项目类型
                customerCompanyId: "", // 项目客户
                systemsInvolvedId: "", // 软件信息
                status: "", // 状态
                finishTimeLeft: "", // 完成日期 - start
                finishTimeRight: "", // 完成日期 - end
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            searchFormrules: {},
            typeOpts: [
                { label: "全部", value: "" },
                { label: "正常", value: "NORMAL" },
                { label: "增补费用", value: "AUGMENT" },
            ], // 项目类型下拉
            statusOpts: [
                { label: "全部", value: "" },
                { label: "完成", value: "FINISH" },
                { label: "废止", value: "ABOLISH" },
            ], // 状态下拉

            tableData: {
                data: [],
                columns: [
                    { id: "createTimeFormat", label: "储备日期", width: "100" },
                    { id: "finishTime", label: "完成日期", width: "100" },
                    { id: "name", label: "项目名称", width: "100" },
                    { id: "content", label: "项目内容", width: "100" },
                    {
                        id: "customerCompanyName",
                        label: "项目客户",
                        width: "100",
                    },
                    { id: "sysNames", label: "软件信息", width: "100" },
                    { id: "money", label: "项目金额（元）", width: "100" },
                    { id: "type", label: "类型", width: "100" },
                    { id: "status", label: "状态", width: "100" },
                ],
            },
        };
    },

    created() {},

    mounted() {
        // 查询列表
        this.getList();
    },

    methods: {
        // 查看详情
        details(row) {
            this.$router.push({
                path: "/reserveProject/reserveProjectDetails",
                query: {
                    status: "查看",
                    step: "完成",
                    id: row.id,
                },
            });
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 清除下拉树选择
                    this.$refs.selectTree.cleanSelect();
                    this.$refs.selectProject.cleanSelect();
                    // 查询列表
                    this.getList();
                }
            });
        },
        //项目客户的选择
        selectTreeCustClick(node) {
            this.searchForm.customerCompanyId = node.id;
        },
        //项目客户的清空
        handClear(){
            this.searchForm.customerCompanyId = "";
        },
        // 项目产品的选择
        selectSystem(data) {
            this.searchForm.systemsInvolvedId = data.value;
        },
        // 查询列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.reserveProjectList(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        //项目客户的选择
        projectCustClick(node) {
            this.searchForm.custUser = node.label;
            this.searchForm.custUserId = node.id;
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 285 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
.searchForm {
    height: auto;
}
.reserveProjectRecord ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProjectRecord ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
</style>
